export const theme = {
	palette: {
		background: {
			default: "#F6F7F8"
		},
		primary: {
			light: "#eda800",
			main: "#a2c517",
			dark: "#9ab91f"
		},
		secondary: {
			light: "#e7ba4c",
			main: "#eda800",
			dark: "#9f7203"
		},
		text: {
			primary: "#21323a",
			secondary: "#21323A"
		}
	}
};
