import React, { useContext, Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { StylesProvider } from "@material-ui/core/styles";
import { theme as customTheme } from "./themes/project";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import WebProfileContext from "@micado-digital/react-ui/context/WebProfileContext";

const Powermonitor = loadable(() => import("./lib/components/Powermonitor"));
const ReactPwaAlert = loadable(() => import("@micado-digital/react-pwa-alert/ReactPwaAlert"));

const theme = createTheme(customTheme);

const { REACT_APP_PATH } = process.env;

const $powermonitor = document.querySelectorAll("#powermonitor");

if ($powermonitor) {
	$powermonitor.forEach(element => {
		const statusLabel01 = element?.getAttribute("data-status-label-01");
		const statusLabel02 = element?.getAttribute("data-status-label-02");
		const statusText01 = element?.getAttribute("data-status-text-01");
		const statusText02 = element?.getAttribute("data-status-text-02");
		const statusTitle01 = element?.getAttribute("data-status-title-01");
		const statusTitle02 = element?.getAttribute("data-status-title-02");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={
											<Powermonitor
												statusLabel01={statusLabel01}
												statusLabel02={statusLabel02}
												statusText01={statusText01}
												statusText02={statusText02}
												statusTitle01={statusTitle01}
												statusTitle02={statusTitle02}
											/>
										}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

const $pwaAlert = document.querySelectorAll("#pwa-alert");

const PwaAlert = ({ checkCookie }) => {
	const [profileData] = useContext(WebProfileContext);
	const { pwaShowInstallHint = false, pwaShowInstallHintReminder } = profileData || {};

	if (!pwaShowInstallHint) return <></>;

	return (
		<ReactPwaAlert
			appIcon={`${REACT_APP_PATH}/img/pwa/apple-icon-180.png`}
			checkCookie={checkCookie}
			reminder={pwaShowInstallHintReminder}
		/>
	);
};

if ($pwaAlert) {
	$pwaAlert.forEach(element => {
		const checkCookie = element?.getAttribute("data-check-cookie") === "true";

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<WebPageData>
									<WebProfile>
										<PwaAlert checkCookie={checkCookie} />
									</WebProfile>
								</WebPageData>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}
